import { init as initAuth, store as authStore } from '@/services/auth'
import { init as initSupport } from '@/services/support'
import { config } from '../app.config.mjs'

/**
 * Bootstrap third-party integrations
 *
 * @todo If/when Astro <ViewTransitions /> enabled ensure this script is re-executed on
 *    `astro:page-load`. <ViewTransitions /> are currently disabled due to pollution of global
 *     scope and improper teardown.
 * @see https://events-3bg.pages.dev/jotter/astro/scripts/
 */
try {
  initSupport({ config })

  await initAuth()

  // Pre-fill Customer Support widget with authenticated User identifier
  authStore.subscribe((clerk) => {
    if (!(clerk && clerk.loaded)) {
      return
    }

    if (config.auth.enabled && clerk.user) {
      const email = clerk.user.emailAddresses[0]?.emailAddress
      email && window.$crisp.push(['set', 'user:email', [email]])

      const fullName = clerk.user.fullName
      fullName && window.$crisp.push(['set', 'user:nickname', [fullName]])
    }
  })
} catch (err) {
  console.error(err)
}
