import { Clerk } from '@clerk/clerk-js'
import { atom } from 'nanostores'

export const store = atom<Clerk | null>(null)

export const init = async () => {
  if (window.clerk?.loaded) {
    return
  }

  try {
    if (!window.clerk) {
      window.clerk = new Clerk(import.meta.env.PUBLIC_ASTRO_APP_CLERK_PUBLISHABLE_KEY)
      store.set(window.clerk)
    }

    await window.clerk.load()
  } catch (err) {
    console.debug(err)
  }
}
