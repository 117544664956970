import { Crisp } from 'crisp-sdk-web'
import type { AppConfig } from '@piktur/types/config'

export interface Options {
  config: Pick<AppConfig, 'customerSupport' | 'auth'>
}

/**
 * Crisp will be hidden for any matched route declared in `config.customerSupport.exclude`
 * Use <ButtonSupport> instead to show it (user clicks on the button to show Crisp)
 *
 * @todo Incompatible with <ViewTransitions />. You might publish events on `astro:page-load`
 *    This script pollutes global scope `window.$__CRISP_INCLUDED` and previous DOM elements
 *    are orphaned.
 */
export const init = ({ config }: Options) => {
  const { id, routes: { exclude } = {} } = config.customerSupport

  if (!id) {
    return
  }

  window.CRISP_WEBSITE_ID = id

  window.$crisp = []
  window.$crisp.push(['safe', true])

  Crisp.configure(window.CRISP_WEBSITE_ID, {
    autoload: true,
  })

  if (exclude?.includes(window.location.pathname)) {
    window.$crisp.push(['do', 'chat:hide'])
    window.$crisp.push(['on', 'chat:closed', () => window.$crisp.push(['do', 'chat:hide'])])
  } else {
    window.$crisp.push(['do', 'chat:show'])
  }
}
